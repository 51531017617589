/*******************************
     User Global Variables
*******************************/
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #e5f6f9;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #66cadf;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #48b4ca;
}
